<template>
  <ResultsTemplate title="Villes">
    <template v-slot:body>
      <v-data-table
        :headers="headers"
        :items="$store.state.cities"
        item-key="Nom"
        sort-by="Nom"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:item.laboratories="{ item }">
          {{
            filter_length(item.laboratories, $store.state.deleted_laboratories)
          }}
        </template>
        <template v-slot:item.practitioners="{ item }">
          {{
            filter_length(
              item.practitioners,
              $store.state.deleted_practitioners
            )
          }}
        </template>
      </v-data-table>
    </template>
  </ResultsTemplate>
</template>

<script>
import ResultsTemplate from "@/components/templates/ResultsTemplate";

export default {
  components: {
    ResultsTemplate,
  },
  data() {
    return {
      search: undefined,
      headers: [
        {
          text: "Ville",
          align: "start",
          value: "properties.city",
          groupable: false,
        },
        {
          text: "Population",
          value: "properties.population",
          align: "right",
          groupable: false,
        },
        { text: "Laboratoires", value: "laboratories", align: "right" },
        { text: "Médecins", value: "practitioners", align: "right" },
      ],
    };
  },
  methods: {
    filter_length: function(items, deleted_list) {
      return items.filter(
        (item) =>
          !deleted_list.find(
            (talbe_element) =>
              talbe_element.Nom === item.Nom &&
              talbe_element.Adresse === item.Adresse
          )
      ).length;
    },
  },
};
</script>

<style></style>
