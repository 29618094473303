<template>
  <ResultsTemplate title="Médecins Généralistes">
    <template v-slot:body>
      <v-text-field
        label="Chercher..."
        v-model="search"
        hide-details
        clearable
      ></v-text-field>

      <v-data-table
        :headers="headers"
        :items="practitioners"
        item-key="Nom"
        sort-by="Groupe"
        group-by="Ville"
        class="elevation-1"
        :search="search"
        show-group-by
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            @click="
              $store.commit('addItemInDeletedArray', {
                array: 'deleted_practitioners',
                item: item,
              })
            "
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </template>
  </ResultsTemplate>
</template>

<script>
import ResultsTemplate from "@/components/templates/ResultsTemplate";

export default {
  components: {
    ResultsTemplate,
  },
  props: {
    practitioners: {
      type: Array,
    },
  },
  data() {
    return {
      search: undefined,
      headers: [
        {
          text: "Nom",
          align: "start",
          value: "Nom",
          groupable: false,
        },
        { text: "Adresse", value: "Adresse", align: "right", groupable: false },
        { text: "Ville", value: "Ville", align: "right" },
        { text: "Profession", value: "Profession", align: "right" },
        { text: "", value: "actions", align: "right", groupable: false },
      ],
    };
  },
};
</script>

<style></style>
