<template>
  <ResultsTemplate title="Statistiques">
    <template v-slot:body>
      <!-- <div class="font-weight-black font-size--20 pt-4"> -->
      <p class="text-left">
        <span class="primary--text"
          >{{ population | localeString }} personnes</span
        >
        habitent.
      </p>
      <p class="text-left">
        <span class="primary--text"
          >{{ laboratories | localeString }} laboratoires
        </span>
        sont implantés.
      </p>
      <p class="text-left">
        <span class="primary--text"
          >{{ practitioners | localeString }} médecins
        </span>
        généralistes exercent.
      </p>
    </template>
  </ResultsTemplate>
</template>

<script>
import ResultsTemplate from "@/components/templates/ResultsTemplate";

export default {
  components: {
    ResultsTemplate,
  },
  props: {
    laboratories: {
      type: Number,
    },
    practitioners: {
      type: Number,
    },
  },
  computed: {
    population() {
      let population = 0;

      this.$store.state.cities.forEach(
        (city) => (population += city.properties.population)
      );

      return population;
    },
  },
  filters: {
    localeString: function(value) {
      return value.toLocaleString("fr-FR");
    },
  },
};
</script>

<style></style>
