<template>
  <div>
    <autocomplete
      :search="search"
      placeholder="Entrez le nom de la ville ou son code postale"
      aria-label="Adresse"
      :get-result-value="getResultValue"
      @submit="addCity"
      :autoSelect="true"
      class="autocomplete"
      ref="autocomplete"
    >
      <template #result="{ result, props }">
        <li v-bind="props">
          <div>
            {{ result.properties.name }}, {{ result.properties.postcode }}
            {{ result.properties.city }}
          </div>
        </li>
      </template>
    </autocomplete>
  </div>
</template>

<script>
import Autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";

export default {
  components: {
    Autocomplete,
  },
  methods: {
    async addCity(city) {
      this.$store.dispatch("addCity", city);

      this.$refs.autocomplete.value = "";
    },

    search(input) {
      if (input.length < 3) return [];

      const url = `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(
        input
      )}&limit=15&type=municipality`;

      return fetch(url)
        .then((response) => response.json())
        .then((data) => {
          return data.features;
        })
        .catch((e) => {
          console.log(e);
          return [];
        });
    },
    getResultValue(result) {
      return `${result.properties.name}, ${result.properties.postcode} ${result.properties.city}`;
    },
  },
};
</script>

<style></style>
