<template>
  <div id="mapContainer" class="content" :style="{ height: height }"></div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  data() {
    return {
      init: false,
      annotations: [],
    };
  },
  mounted() {
    const mapkitJS_key = this.$mapkitJS_key;

    mapkit.init({
      authorizationCallback: function(done) {
        done(mapkitJS_key);
      },
    });

    this.map = new mapkit.Map("mapContainer", {
      showsCompass: mapkit.FeatureVisibility.Hidden,
    });
  },
  computed: {
    map: {
      get() {
        return this.$store.state.map;
      },
      set(value) {
        this.$store.state.map = value;
      },
    },
    height() {
      if (window.innerWidth < 960) return "272px";
      return "372px";
    },
  },
  methods: {
    initMap() {
      setTimeout(() => {
        var region = new mapkit.CoordinateRegion(
          new mapkit.Coordinate(48.5833, 7.75),
          new mapkit.CoordinateSpan(2.5)
        );
        this.map.setRegionAnimated(region);
      }, 1000);

      this.map.addEventListener("single-tap", (event) => {
        var coordinate = this.map.convertPointOnPageToCoordinate(
          event.pointOnPage
        );

        this.$store.dispatch("addCity", {
          annotation: new mapkit.MarkerAnnotation(coordinate),
        });
      });
      this.init = true;
    },
  },
  watch: {
    map(value) {
      if (this.init) return;
      if (value === null) return;

      this.initMap();
    },
  },
};
</script>

<style>
.content {
  min-height: 40vh;
  min-width: 50vw;
}
</style>
