<template>
  <v-container>
    <v-card elevation="0">
      <v-card-title class="primary--text font-weight-black font-size--26">
        B2A - Statistiques
      </v-card-title>
      <v-card-text class="pa-5">
        <SearchBar />

        <CitiesChips />

        <MapLayout />

        <!-- <FilterSection /> -->

        <GeneralResults
          :laboratories="all_laboratories_filter.length"
          :practitioners="all_practitioners_filter.length"
        />

        <CitiesResults />

        <LaboratoriesResults :laboratories="all_laboratories_filter" />

        <PractitionersResults :practitioners="all_practitioners_filter" />
      </v-card-text>
    </v-card>
    <v-snackbar v-model="$store.state.snackbar" color="primary">
      {{ $store.state.snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="$store.state.snackbar = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-btn
      class="float"
      fab
      :dark="cities.length > 0"
      large
      color="primary"
      @click="dialog_password = true"
      :loading="loading"
      :disabled="cities.length < 1"
    >
      <v-icon dark>
        mdi-download
      </v-icon>
    </v-btn>
    <v-dialog v-model="dialog_password" max-width="500">
      <v-card>
        <v-card-title class="primary white--text">
          Accès protégé
          <v-spacer></v-spacer>
          <v-btn text icon dark @click="dialog_password = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="password"
            label="Mots de passe"
            type="password"
          ></v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="export_csv" :disabled="password < 1">
              Exporter
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable no-undef */

import SearchBar from "@/components/search_bar/SearchBar";
import CitiesChips from "@/components/search_bar/CitiesChips";
import MapLayout from "@/components/map/MapLayout";
// import FilterSection from "@/components/filter/FilterSection";
import GeneralResults from "@/components/general_results/GeneralResults";
import CitiesResults from "@/components/cities_results/CitiesResults";
import PractitionersResults from "@/components/practitioners_results/PractitionersResults";
import LaboratoriesResults from "@/components/laboratories_results/LaboratoriesResults";
import download from "downloadjs";

export default {
  name: "Home",
  components: {
    SearchBar,
    CitiesChips,
    MapLayout,
    // FilterSection,
    GeneralResults,
    LaboratoriesResults,
    PractitionersResults,
    CitiesResults,
  },
  data() {
    return {
      loading: false,
      password: "",
      dialog_password: false,
    };
  },
  computed: {
    cities: {
      get() {
        return this.$store.state.cities;
      },
      set(value) {
        this.$store.state.cities = value;
      },
    },
    map: {
      get() {
        return this.$store.state.map;
      },
      set(value) {
        this.$store.state.map = value;
      },
    },
    practitioners_specialty_selected() {
      return this.$store.state.practitioners_specialty_selected;
    },
    deleted_laboratories: {
      get() {
        return this.$store.state.deleted_laboratories;
      },
      set(value) {
        this.$store.state.deleted_laboratories = value;
      },
    },
    deleted_practitioners: {
      get() {
        return this.$store.state.deleted_practitioners;
      },
      set(value) {
        this.$store.state.deleted_practitioners = value;
      },
    },
    all_laboratories_filter() {
      let all_laboratories_filter = [];

      this.cities.forEach((city) =>
        all_laboratories_filter.push(...city.laboratories)
      );

      all_laboratories_filter = all_laboratories_filter.filter(
        (laboratory) =>
          !this.elementInTable(laboratory, this.deleted_laboratories)
      );

      return all_laboratories_filter;
    },
    all_practitioners_filter() {
      let all_practitioners_filter = [];

      this.cities.forEach((city) =>
        all_practitioners_filter.push(...city.practitioners)
      );

      // Filter with deleted array and specialty slected array
      return all_practitioners_filter.filter(
        (practitioner) =>
          !this.elementInTable(practitioner, this.deleted_practitioners)
      );
    },
  },
  methods: {
    async export_csv() {
      this.loading = true;
      let data = {};
      const cities = this.cities.map((city) => city.properties.city);
      this.cities.forEach((city) => {
        data = {
          ...data,
          [city.properties.city]: {
            population: city.properties.population,
            doctors: city.practitioners
              .filter(
                (practitioner) =>
                  !this.elementInTable(practitioner, this.deleted_practitioners)
              )
              .map((practitioner) => {
                return {
                  name: practitioner.Nom,
                  specialty: practitioner.Profession,
                  city: city.properties.city,
                };
              }),
            laboratories: city.laboratories
              .filter(
                (laboratory) =>
                  !this.elementInTable(laboratory, this.deleted_laboratories)
              )
              .map((laboratory) => {
                return { name: laboratory.Nom, group: laboratory.Groupe };
              }),
          },
        };
      });

      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        cities: cities,
        data: data,
        password: this.password,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const url =
        "https://us-central1-statistics-geo.cloudfunctions.net/exports";
      // const url = "http://localhost:5001/statistics-geo/us-central1/exports";

      fetch(url, requestOptions)
        .then((response) => {
          if (response.status != 200) {
            throw response;
          }
          return response;
        })
        .then((response) => response.blob())
        .then((blob) => download(blob, "export_croissance.xlsx"))
        .catch((err) => {
          console.log(err);
          if (err.status == 401) {
            this.$store.state.snackbar_text = "Accès non autorisé";
            return (this.$store.state.snackbar = true);
          }
          this.$store.state.snackbar_text = "Une erreur est survenue";
          this.$store.state.snackbar = true;
        })
        .finally(() => {
          this.loading = false;
          this.password = "";
          this.dialog_password = false;
        });
    },
    elementInTable(element, table) {
      return !!table.find(
        (talbe_element) =>
          talbe_element.Nom === element.Nom &&
          talbe_element.Adresse === element.Adresse
      );
    },
  },
};
</script>

<style>
.font-size--20 {
  font-size: 20px;
}

.font-size--24 {
  font-size: 24px;
}

.font-size--26 {
  font-size: 26px !important;
}

.float {
  position: fixed;
  bottom: 40px;
  right: 40px;
}
</style>
