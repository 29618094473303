import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";

Vue.config.productionTip = false;
Vue.prototype.$mapkitJS_key =
  "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjRXUkNIN1BNTjcifQ.eyJpc3MiOiJRMks4NjVWNDNHIiwiaWF0IjoxNzExOTAwODgzLjU1MiwiZXhwIjoxNzExOTA0NDgzLjU1Mn0.QNey2RMktWbbTg_cvSgG5vwLRoQviJzmdn2GejQCDalolISQUW2Ba36bEvNvXzysk2I-uFcAXXF6ft4eVqeTeg";

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
