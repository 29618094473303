<template>
  <ResultsTemplate title="Map">
    <template v-slot:body>
      <Map @add:city="$emit('add:city', $event)" />
    </template>
  </ResultsTemplate>
</template>

<script>
import ResultsTemplate from "@/components/templates/ResultsTemplate";
import Map from "./Map";

export default {
  components: {
    ResultsTemplate,
    Map,
  },
};
</script>

<style></style>
