<template>
  <div>
    <v-chip
      v-for="(city, index) in $store.state.cities"
      :key="index"
      class="ma-1 mt-4"
      color="primary"
      close
      @click:close="deleteCity(city, index)"
    >
      {{ city.properties.city }}
    </v-chip>
    <v-chip
      v-for="city in $store.state.loading_cities"
      :key="city + i"
      color="primary"
      class="ma-1 mt-4"
    >
      <v-progress-circular
        width="2"
        style="height:20px"
        indeterminate
        color="white"
      ></v-progress-circular>
    </v-chip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      i: 0,
    };
  },
  methods: {
    deleteCity(city, index) {
      this.deleted_laboratories = this.deleted_laboratories.filter(
        (laboratory) => laboratory.Ville !== city.properties.city
      );
      this.deleted_practitioners = this.deleted_practitioners.filter(
        (laboratory) => laboratory.Ville !== city.properties.city
      );

      city.laboratories.forEach((labo) =>
        this.map.removeAnnotation(labo.Annotation)
      );
      city.practitioners.forEach((pract) =>
        this.map.removeAnnotation(pract.Annotation)
      );
      this.map.removeAnnotation(city.annotation);

      this.cities.splice(index, 1);
    },
  },
  computed: {
    map() {
      return this.$store.state.map;
    },
    cities: {
      get() {
        return this.$store.state.cities;
      },
      set(value) {
        this.$store.state.cities = value;
      },
    },
    deleted_laboratories: {
      get() {
        return this.$store.state.deleted_laboratories;
      },
      set(value) {
        this.$store.state.deleted_laboratories = value;
      },
    },
    deleted_practitioners: {
      get() {
        return this.$store.state.deleted_practitioners;
      },
      set(value) {
        this.$store.state.deleted_practitioners = value;
      },
    },
  },
  watch: {
    "$store.state.loading_cities"() {
      this.i++;
    },
  },
};
</script>

<style></style>
