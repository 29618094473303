<template>
  <div class="font-weight-black font-size--20 pt-4">
    <v-row align="center">
      <v-col cols="auto">
        <p class="primary--text font-size--24 ma-0">
          {{ title }}
        </p>
      </v-col>
      <v-col>
        <v-divider class="my-4 primary"></v-divider>
      </v-col>
    </v-row>
    <div class="mt-2">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      requried: true,
    },
  },
};
</script>

<style></style>
