/* eslint-disable no-undef */
import Vue from "vue";
import Vuex from "vuex";
import { getLaboratories, getPractitioner } from "@/api/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cities: [],
    loading_cities: 0,
    map: null,
    deleted_laboratories: [],
    deleted_practitioners: [],
    practitioners_specialty: [],
    practitioners_specialty_selected: [],
    snackbar: false,
    snackbar_text: "Une erreur est survenue",
  },
  mutations: {
    addItemInDeletedArray(state, { array, item }) {
      if (
        !state[array].find(
          (talbe_element) =>
            talbe_element.Nom === item.Nom &&
            talbe_element.Adresse === item.Adresse
        )
      ) {
        state[array].push(item);
        state.map.removeAnnotation(item.Annotation);
      }
    },
    addPractitionerSpecialty(state, new_specialty) {
      if (
        !state.practitioners_specialty.find(
          (specialty) => specialty === new_specialty
        ) &&
        new_specialty
      ) {
        state.practitioners_specialty.push(new_specialty);
        state.practitioners_specialty_selected.push(new_specialty);
      }
    },
    catchError(state, { city, error_type = "Une erreur est survenue" }) {
      state.snackbar_text = error_type;
      state.snackbar = true;

      if (city.properties) {
        state.deleted_laboratories = state.deleted_laboratories.filter(
          (laboratory) => laboratory.Ville !== city.properties.city
        );
        state.deleted_practitioners = state.deleted_practitioners.filter(
          (laboratory) => laboratory.Ville !== city.properties.city
        );
      }

      if (city.annotation) {
        state.map.removeAnnotation(city.annotation);
      }

      state.loading_cities--;
    },
  },
  actions: {
    async addCity({ state, commit }, city) {
      //Add a loading chip
      state.loading_cities++;

      try {
        //Add General
        if (!city.properties) {
          const url_get_name = `https://api-adresse.data.gouv.fr/reverse/?lon=${city.annotation.coordinate.longitude}&lat=${city.annotation.coordinate.latitude}`;
          const { features: data } = await fetch(
            url_get_name
          ).then((response) => response.json());

          try {
            const url_get_properties = `https://api-adresse.data.gouv.fr/search/?q=${data[0].properties.city}&citycode=${data[0].properties.citycode}&type=municipality`;
            const { features: properties } = await fetch(
              url_get_properties
            ).then((response) => response.json());

            Object.assign(city, properties[0]);
          } catch {
            commit("catchError", {
              city: city,
              error_type: "Aucune commune trouvée",
            });
            return;
          }
        }

        // Check if city is already selected
        if (
          state.cities.find((el) => el.properties.id === city.properties.id)
        ) {
          commit("catchError", {
            city: city,
            error_type: "La commune est déjà dans la liste",
          });
          return;
        }

        //Create Annotation
        if (!city.annotation) {
          let coordinate = new mapkit.Coordinate(
            city.geometry.coordinates[1],
            city.geometry.coordinates[0]
          );
          let annotation = new mapkit.MarkerAnnotation(coordinate);
          city.annotation = annotation;
        }

        //Add Annotation
        state.map.addAnnotation(city.annotation);

        //Add Laboratories
        if (!city.laboratories) {
          city.laboratories = await getLaboratories(
            city.properties.citycode,
            city.properties.city
          );
        }

        //Add Practitionner
        if (!city.practitioners) {
          city.practitioners = await getPractitioner(
            city.properties.citycode,
            city.properties.city
          );
        }
      } catch (e) {
        console.log(e);
        commit("catchError", { city: city });
        return;
      }

      state.loading_cities--;
      state.cities.push(city);
    },
  },
  modules: {},
});
