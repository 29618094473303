/* eslint-disable no-undef */

import $store from "@/store/index";
import GroupeLaboratories from "@/components/laboratories_results/GroupeLaboratories.json";

function addAnnotation({ coordinate, color, icon }) {
  const coordinates = new mapkit.Coordinate(coordinate.lat, coordinate.long);
  const annotation = new mapkit.MarkerAnnotation(coordinates, {
    color: color,
    glyphImage: { 1: `/${icon}.png` }
  });

  $store.state.map.addAnnotation(annotation);

  return annotation;
}

export const getLaboratories = async function(codeInsee, city) {
  return await fetch(
    `https://public.opendatasoft.com/api/records/1.0/search/?dataset=healthref-france-finess&q=&rows=-1&facet=legal_entity_num&refine.libcategagretab=Laboratoires de Biologie M\u00e9dicale&refine.com_code=${codeInsee}`
  )
    .then(resp => resp.json())
    .then(data => data.records.map(laboratory => laboratory.fields))
    .then(data =>
      data.map(laboratory => {
        const annotation = addAnnotation({
          coordinate: {
            lat: laboratory.coord[0],
            long: laboratory.coord[1]
          },
          color: "green",
          icon: "microscope"
        });

        return {
          Nom: laboratory.rslongue,
          Groupe: GroupeLaboratories[laboratory.nofinessej] ? GroupeLaboratories[laboratory.nofinessej] : "Autre Groupe",
          Ville: city,
          Adresse: laboratory.address,
          Annotation: annotation
        };
      })
    );
};

export const getPractitioner = async function(codeInsee, city) {
  const typePractitioner = [
    "M%C3%A9decin%20g%C3%A9n%C3%A9raliste",
    "Gyn%C3%A9cologue+m%C3%A9dical",
    "Cardiologue",
    "Gastro-ent%C3%A9rologue+et+h%C3%A9patologue",
    "Endocrinologue-diab%C3%A9tologue",
    "Rhumatologue"
  ];
  let result = [];
  await Promise.all(
    typePractitioner.map(type =>
      fetch(
        `https://public.opendatasoft.com/api/records/1.0/search/?dataset=medecins&refine.code_insee=${codeInsee}&refine.libelle_profession=${type}&rows=-1`
      )
        .then(response => response.json())
        .then(data => {
          for (const practitioner of data.records) {
            if (!result.find(el => el.Nom === practitioner.fields.nom && el.Profession === practitioner.fields.libelle_profession)) {
              const annotation = addAnnotation({
                coordinate: {
                  lat: practitioner.fields.coordonnees[0],
                  long: practitioner.fields.coordonnees[1]
                },
                color: "blue",
                icon: "stethoscope"
              });

              result.push({
                Nom: practitioner.fields.nom,
                Adresse: practitioner.fields.adresse,
                Profession: practitioner.fields.libelle_profession,
                Ville: city,
                Annotation: annotation,
                specialty: practitioner.fields.column_12
              });

              $store.commit("addPractitionerSpecialty", practitioner.fields.column_12);
            }
          }
        })
    )
  );
  return result;
};
